var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[(!_vm.disableSearch)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{style:({
          border: '1px solid #d5d5d5',
        }),attrs:{"label":"Search","dense":"","prepend-inner-icon":"mdi-magnify","hide-details":true,"clearable":true,"background-color":"rgba(0, 0, 0, 0.1))","solo":true,"flat":true},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),(!!_vm.buttonCreate)?_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary","elevation":"2","small":"","type":"button"},on:{"click":function($event){return _vm.onClickCreate()}}},[_vm._v(" Tambah "+_vm._s(this.title)+" ")]):_vm._e(),_vm._t("action")],2),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItems,"footer-props":_vm.footerProps,"loading":_vm.loading,"options":_vm.options,"show-select":_vm.showSelect},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.slots),function(slot){return {key:slot.name,fn:function(ref){
        var item = ref.item;
return [_vm._t(slot.name,[_vm._v(_vm._s(item[slot.value]))],{"item":item})]}}}),(!_vm.disableAction)?{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_vm._t("item.action",null,{"item":item}),(_vm.canUpdate || _vm.canView)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"btn btn-icon btn-light btn-xs mx-1",attrs:{"href":"#"},on:{"click":function($event){return _vm.onClickUpdate(item)}}},'a',attrs,false),on),[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[(_vm.canUpdate)?_c('inline-svg',{attrs:{"src":_vm.asset('/media/svg/icons/Communication/Write.svg')}}):(_vm.canView)?_c('inline-svg',{attrs:{"src":_vm.asset('/media/svg/icons/Files/File.svg')}}):_vm._e()],1)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.canUpdate ? "Edit" : "Lihat Detil"))])]):_vm._e(),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.canDelete)?_c('a',_vm._g(_vm._b({staticClass:"btn btn-icon btn-light btn-xs mx-1",attrs:{"href":"#"},on:{"click":function($event){return _vm.onClickDelete(item)}}},'a',attrs,false),on),[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('inline-svg',{attrs:{"src":_vm.asset('/media/svg/icons/General/Trash.svg')}})],1)]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Hapus")])]):_vm._e()]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }